import React, { useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import EditorToolbar, { formats, modules } from './QuillToolbar';

const QuillEditor = ({ value, ...rest }) => {
  const editorRef = useRef(null);
  const [isEmpty, setIsEmpty] = useState(false);

  useEffect(() => {
    if (editorRef?.current) {
      const checkIfEmpty =
        editorRef?.current?.getEditor()?.getText()?.trim()?.length === 0;
      setIsEmpty(checkIfEmpty);
    }
  }, [value]);

  return (
    <div className="text-editor">
      <EditorToolbar />
      <ReactQuill
        ref={editorRef}
        theme="snow"
        value={value}
        placeholder="Write something awesome..."
        modules={modules}
        formats={formats}
        className={`editor ${isEmpty ? 'editor-empty' : ''}`}
        {...rest}
      />
    </div>
  );
};

export default QuillEditor;
