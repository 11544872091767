import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  Button,
  Card,
  Col,
  ColorPicker,
  Form,
  Input,
  message,
  Row,
  Space,
  Typography,
  Upload,
} from 'antd';
import { ColorFactory } from 'antd/es/color-picker/color';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../AppContext';
import { ROUTES } from '../../common/constants';
import {
  beforeUpload,
  fileUpload,
  formValidatorRules,
  handleProtectedNavigation,
} from '../../common/utils';
import QuillEditor from '../../components/Editor/QuillEditor';
import LoaderComponent from '../../components/LoaderComponent';
import RouterPrompt from '../../components/RouterPrompt';
import { toastSuccess } from '../../components/ToastContainer';
import usePreventNavigation from '../../hooks/usePreventNavigation';
import useRouter from '../../hooks/useRouter';
import { CREATE_CONFIG, UPDATE_CONFIG } from './graphql/Mutations';
import {
  GET_COMPANYLOGO_UPLOAD_SIGNEDURL,
  GET_CONFIG,
} from './graphql/Queries';

const { required, name, instruction } = formValidatorRules;

function OnBoarding() {
  const [btnLoading, setBtnLoading] = useState(false);
  const [form] = Form?.useForm();
  const { navigate } = useRouter();
  const [showPrompt, setShowPrompt] = useState(false);
  const [isPrompt, setIsPrompt] = useState(false);
  const [curImg, setCurImg] = useState('');
  const [config, setConfig] = useState({});
  const [isDirty, setIsDirty] = useState(true);
  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);
  const { getCurrentUser, getToken, dispatch } = useContext(AppContext);
  const [btnDisable, setBtnDisable] = useState(false);
  const currentUser = getCurrentUser();
  const token = getToken();
  const accessToken = token;
  const refreshToken = currentUser?.refreshToken;
  const [createConfig] = useMutation(CREATE_CONFIG, {
    onCompleted: () => {
      navigate(ROUTES.MAIN);
      setBtnLoading(false);
    },
    onError() {},
  });

  const [signurlImage] = useLazyQuery(GET_COMPANYLOGO_UPLOAD_SIGNEDURL, {
    fetchPolicy: 'network-only',
  });

  const [updateConfig] = useMutation(UPDATE_CONFIG, {
    fetchPolicy: 'network-only',
    onCompleted: () => {
      navigate(ROUTES.MAIN);
      setBtnLoading(false);
    },
    onError() {},
  });

  const setUser = (configValue) => {
    dispatch({
      type: 'SET_CURRENT_USER',
      data: {
        accessToken,
        ...currentUser,
        ...configValue,
        isConfigSet: true,
        refreshToken,
      },
    });
  };

  const { loading, refetch } = useQuery(GET_CONFIG, {
    onCompleted: ({ getConfig }) => {
      setConfig(getConfig?.config);
      setCurImg(getConfig?.config?.companyLogo);
      form.setFieldsValue({
        ...getConfig?.config,
        primaryColor: new ColorFactory(
          getConfig?.config?.primaryColor || '#000000',
        ),
      });
    },
    onError() {},
    fetchPolicy: 'network-only',
  });

  const uploadButton = (
    <div>
      {btnLoading ? <LoadingOutlined /> : <PlusOutlined />}
      <div className="mt-8">Upload</div>
    </div>
  );

  const handleBack = () => {
    setIsDirty(false);
    setIsPrompt(!handleProtectedNavigation(!showPrompt, navigate, -1));
  };

  const handleShowPrompt = () => {
    setShowPrompt(true);
    setBtnDisable(false);
  };

  const handleOk = () => {
    setIsDirty(false);
    handleProtectedNavigation(true, navigate, -1);
  };

  const handleClose = () => {
    setIsDirty(true);
    setIsPrompt(false);
  };

  const imgUpload = async (file) => {
    setUploading(true);
    const { data } = await signurlImage({
      variables: {
        data: {
          fileName: file?.name,
        },
      },
    });

    if (data?.getCompanyLogoUploadSignedUrl?.key) {
      try {
        await fileUpload(data?.getCompanyLogoUploadSignedUrl?.signedUrl, file);
        setUploading(false);
        return data?.getCompanyLogoUploadSignedUrl?.key;
      } catch (error) {
        //
      }
    }
  };

  const onFinish = async (values) => {
    setBtnLoading(true);
    const selectedFile = fileList?.[0]?.originFileObj;
    let img = null;

    if (!selectedFile) {
      img = values?.companyLogo;
    } else {
      img = await imgUpload(selectedFile);
    }

    const configValue = {
      ...values,
      primaryColor: `#${values?.primaryColor?.toHex()}`,
      companyLogo: selectedFile ? img : '',
      configId: config?.id,
    };

    try {
      if (currentUser?.isVerified && currentUser?.isConfigSet) {
        const { data } = await updateConfig({
          variables: {
            data: configValue,
          },
        });
        if (data) {
          const { data: configData } = await refetch();
          if (configData?.getConfig) {
            setUser(configData?.getConfig?.config);
            toastSuccess('Config updated successfully!');
            navigate(ROUTES.MAIN);
          }
        }
      } else {
        const { data } = await createConfig({
          variables: {
            data: configValue,
          },
        });
        if (data) {
          const { data: configData } = await refetch();
          if (configData?.getConfig) {
            setUser(configData?.getConfig?.config);
            toastSuccess('Config created successfully!');
            navigate(ROUTES.MAIN);
          }
        }
      }
    } catch (error) {
      //
    } finally {
      setBtnLoading(false);
    }
  };

  usePreventNavigation(isDirty);

  useEffect(() => {
    let disableTime;
    if (config) {
      disableTime = setTimeout(() => {
        setBtnDisable(true);
      }, 500);
    }
    return () => {
      clearTimeout(disableTime);
    };
  }, [config]);

  return (
    <>
      <LoaderComponent spinning={loading}>
        <Form
          form={form}
          className="sticky-action-form"
          onFieldsChange={handleShowPrompt}
          layout="vertical"
          onFinish={onFinish}
        >
          <Card
            className="ant-body-scroll"
            title={
              <>
                <div className="d-flex justify-between">
                  <Typography.Title level={4} className="m-0 head-title">
                    Onboarding
                  </Typography.Title>
                  <div>
                    <Space>
                      {currentUser?.isConfigSet && (
                        <Button onClick={handleBack} disabled={btnLoading}>
                          Cancel
                        </Button>
                      )}
                      <Button
                        type="primary"
                        loading={btnLoading || uploading}
                        htmlType="submit"
                        disabled={btnDisable}
                      >
                        Save
                      </Button>
                    </Space>
                  </div>
                </div>
              </>
            }
          >
            <div className="card-body-wrapper">
              <Row gutter={[16, 16]}>
                <Col xs={24} lg={24} xl={24}>
                  <Form.Item name="companyLogo" label="Company Logo">
                    <Upload
                      name="avatar"
                      listType="picture-card"
                      className="avatar-uploader"
                      showUploadList={false}
                      beforeUpload={beforeUpload}
                      accept="image/png, image/jpeg"
                      onChange={(info) => {
                        if (info?.file?.size / 1024 / 1024 > 5) {
                          message.error('Image must be smaller than 5MB!');
                          return;
                        }
                        setFileList(info?.fileList);
                        setCurImg(
                          URL.createObjectURL(
                            info?.fileList?.[0]?.originFileObj,
                          ),
                        );
                      }}
                      fileList={[]}
                    >
                      {curImg ? (
                        <img src={curImg} alt="avatar" className="full-width" />
                      ) : (
                        uploadButton
                      )}
                    </Upload>
                    {curImg?.companyLogo}
                  </Form.Item>
                </Col>
                <Col xs={24} lg={12} xl={12}>
                  <Form.Item
                    name="companyName"
                    label="Company Name"
                    rules={[
                      { ...required, message: 'Please Enter First Name' },
                      name,
                    ]}
                  >
                    <Input placeholder="Enter Company Name" />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={12} xl={12}>
                  <Form.Item name="primaryColor" label="Primary Color">
                    <ColorPicker showText />
                  </Form.Item>
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  xxl={24}
                  className="instructions"
                >
                  <Form.Item
                    name="instructions"
                    label="Instructions"
                    rules={[{ required: true }, instruction]}
                  >
                    <QuillEditor />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Card>
        </Form>
      </LoaderComponent>
      <RouterPrompt
        isPrompt={isPrompt}
        handleOK={handleOk}
        handleCancel={handleClose}
      />
    </>
  );
}

export default OnBoarding;
